<script>
import ButtonIcon from '/~/components/base/button/icon/button-icon.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseAvatar from '/~/components/base/avatar/base-avatar.vue'
import { useGroups } from '/~rec/composables/groups'
import { useRecProfile } from '/~rec/composables/profile'

export default {
  name: 'users-list',
  components: {
    BaseIcon,
    BaseAvatar,
    ButtonIcon,
  },
  props: {
    multi: {
      type: Boolean,
      default: false,
    },
    currentUser: {
      type: Object,
      default: null,
    },
    currentUsers: {
      type: Array,
      default: () => [],
    },
    excludeMe: {
      type: Boolean,
      default: true,
    },
    filterUsers: {
      type: Function,
      default: (v) => v,
    },
    searchValue: {
      type: String,
      default: '',
    },
    disabledLabel: {
      type: String,
      default: '',
    },
    disableUser: {
      type: Function,
      default: () => false,
    },
    selectable: {
      type: Boolean,
      default: true,
    },
    removable: {
      type: Boolean,
      default: false,
    },
    removeHandler: {
      type: Function,
      default: () => ({}),
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { groups, allMembers } = useGroups()
    const { myRecId } = useRecProfile()

    return { groups, myRecId, allMembers }
  },
  data() {
    return {
      selectedUser: this.currentUser,
      selectedUsers: this.currentUsers,
      usersToRemove: [],
    }
  },
  computed: {
    availableUsers() {
      return this.filterUsers(this.allMembers).filter((user) => {
        const fullName = user?.fullName?.toLowerCase()
        const email = user?.email?.toLowerCase()

        const searchLowerCase = this.searchValue.toLowerCase()

        return (
          fullName.indexOf(searchLowerCase) >= 0 ||
          email.indexOf(searchLowerCase) >= 0
        )
      })
    },
  },
  watch: {
    currentUser(value) {
      this.selectedUser = value
    },
    currentUsers: {
      handler(value) {
        this.selectedUsers = [...value]
      },
      deep: true,
    },
  },
  methods: {
    onUserClick(user) {
      if (!this.selectable) return

      if (this.multi) {
        const idx = this.selectedUsers.map((u) => u.id).indexOf(user.id)

        if (idx < 0) {
          this.selectedUsers.push(user)
        } else {
          this.selectedUsers.splice(idx, 1)
        }
      } else {
        this.selectedUser = user
      }

      this.$emit(
        'user-select',
        this.multi ? this.selectedUsers : this.selectedUser
      )
    },
    async onUserRemove(user) {
      this.usersToRemove.push(user.id)
      await this.removeHandler(user.id)

      const idx = this.usersToRemove.indexOf(user.id)

      this.usersToRemove.splice(idx, 1)
    },
    onSave() {
      this.$emit('save', this.selectedUsers)
    },
    getSubtitle(u) {
      return this.removable
        ? u.email
        : this.disableUser(u.id)
        ? this.disabledLabel
        : u.email
    },
    isSelected(id) {
      return this.selectedUsers.map((u) => u.id).indexOf(id) >= 0
    },
    checkUserDisabled(id) {
      const isMe = this.excludeMe && this.myRecId === id

      return isMe || this.isUserBeingRemoved(id) || this.disableUser(id)
    },
    isUserBeingRemoved(id) {
      return this.usersToRemove.includes(id)
    },
  },
}
</script>

<template>
  <div class="grid overflow-y-auto rounded-xl">
    <div v-if="!availableUsers.length" class="text-center">No members</div>

    <div
      v-for="u in availableUsers"
      :key="u.id"
      class="flex max-w-full items-center truncate rounded-lg"
      :class="{
        'p-[5px]': small,
        'py-2.5': !small,
        'pointer-events-none': checkUserDisabled(u.id),
        'cursor-pointer hover:bg-gray-50': selectable,
      }"
      @click="onUserClick(u)"
    >
      <div class="relative my-auto mr-[15px]">
        <div
          v-if="multi && !removable && isSelected(u.id)"
          class="absolute right-0 bottom-0 z-1 -mb-0.5 -mr-2.5 rounded-full bg-white text-fg-success text-white"
          :class="{
            hidden: disableUser(u.id),
          }"
          style="border: 3px solid white"
        >
          <base-icon svg="success" :size="small ? 16 : 24" />
        </div>

        <base-avatar
          :size="small ? 'xs' : 'lg'"
          :src="u.avatarUrl"
          :abbr="u.initials"
        />
      </div>
      <div
        class="flex truncate"
        :class="{
          'w-full flex-row': small,
          'flex-col': !small,
          'opacity-50': checkUserDisabled(u.id),
        }"
      >
        <span
          class="w-full truncate pb-[5px] font-bold leading-6 text-eonx-neutral-800"
        >
          {{ u.fullName }}
        </span>
        <span
          class="w-full text-sm font-semibold leading-4 text-eonx-neutral-600"
        >
          {{ getSubtitle(u) }}
        </span>
      </div>
      <div class="ml-auto flex pl-2.5">
        <slot :user="u" :index="u.id" name="user" />
        <div
          v-if="removable"
          class="ml-2.5 w-6"
          :class="{
            'pointer-events-none opacity-50': usersToRemove.length,
          }"
        >
          <span v-if="!(excludeMe && u.id === myRecId)" class="text-fg-error">
            <button-icon
              :icon="isUserBeingRemoved(u.id) ? 'spinner' : 'trash'"
              size="md"
              class="cursor-pointer"
              @click.stop="onUserRemove(u)"
            />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
